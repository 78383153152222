@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./preflight.css";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: #1d1d1d;
  --scroll-behavior: auto;
  scroll-behavior: auto;
}

input,
textarea {
  resize: none !important;
}

.sendbird-message-input {
  height: 60px !important;
}

.sendbird-message-input--textarea {
  border-radius: 56px !important;
  border-color: #eee !important;
}

.sendbird-message-input--placeholder {
  color: #1d1d1d !important;
  left: 24px !important;
}

.sendbird-message-input-text-field:focus {
  box-shadow: none !important;
  border: 2px solid #f87450 !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.sendbird-conversation__messages-padding {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.sendbird-message-input-text-field::-webkit-scrollbar {
  display: none !important;
}

.sendbird-message-input-wrapper__message-input {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.react-modal-sheet-container {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  box-shadow: none !important;
}

.sendbird-conversation {
  border: none !important;
}

.react-modal-sheet-drag-indicator {
  width: 50px !important;
  transition: all linear 0.25s;
}

.react-modal-sheet-backdrop {
  /* background: transparent !important; */
}

.Toastify__toast-container--top-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  top: 45px !important;
  pointer-events: none;
}

.Toastify__toast-container--top-center .Toastify__toast {
  border-radius: 40px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  min-height: auto;
  width: fit-content;
  align-self: center;
}

.Toastify__toast-container--top-center .Toastify__toast--success {
  background-color: #40ad58;
  color: white;
}

.Toastify__toast-container--top-center .Toastify__toast--error {
  color: red;
}

.Toastify__toast-container--top-center .Toastify__toast .Toastify__toast-body {
  padding: 0px;
  margin: 0px;
}

.icon-chevron-down_svg__fill {
  fill: #f87450 !important;
}

.sendbird-conversation__messages-padding {
  padding-bottom: 160px !important;
}

.sendbird-conversation__footer {
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  position: fixed !important;
  left: 0px;
  bottom: 0px;
  z-index: 20;
  background: #fff;
}

.sendbird-place-holder {
  visibility: hidden !important;
}

.react-code-input input[type="number"]::-webkit-outer-spin-button,
.react-code-input input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-code-input input[type="number"] {
  -moz-appearance: textfield;
  width: 40px !important;
  height: 53px !important;
  text-align: center;
  padding-left: 0px !important;
  font-size: 20px !important;
  border-color: #f87450 !important;
  background: transparent !important;
  color: #fff !important;
}

.react-code-input input[type="number"]:focus {
  outline: none !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}

.sendbird-conversation__footer {
  background: transparent !important;
}

.mantine-Modal-inner {
  left: 0px;
}

.mantine-Drawer-inner {
  left: 0px;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

@keyframes mybounce {
  0%,
  to {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
