.touchable {
  position: relative;
  -webkit-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}
.touchable.touching {
  background-color: #eee;
}
@media screen and (min-width: 769px) {
  .touchable {
    cursor: pointer;
  }
}
